const sandwiches = [
    
    {
        name_en: "Turkey & Cheese",
        name_ar: "ديك رومي وجبن",
        description_en: "",
        price: "39",
        img_path: "/five-am-menu-designs/sandwiches/01 Turkey and Cheese_WEB.jpg"
    },

    {
        name_en: "Chicken Cilantro",
        name_ar: "دجاج بالكزبرة",
        description_en: "",
        price: "29",
        img_path: "/five-am-menu-designs/sandwiches/02 Chicken Cilantro_WEB.jpg"
    },

    {
        name_en: "Brie & Bacon",
        name_ar: "بري ولحم مقدد",
        description_en: "",
        price: "39",
        img_path: "/five-am-menu-designs/sandwiches/03  Brie and Bacon_WEB.jpg"
    },

    {
        name_en: "Bacon & Eggs",
        name_ar: "لحم مقدد وبيض",
        description_en: "",
        price: "32",
        img_path: "/five-am-menu-designs/sandwiches/04 Bacon and Eggs_WEB.jpg"
    },

    {
        name_en: "Classic Club",
        name_ar: "كلوب كلاسيكي",
        description_en: "",
        price: "59",
        img_path: "/five-am-menu-designs/sandwiches/05 Club Sandwich_WEB.jpg"
    },

    {
        name_en: "Chicken Sundried Tomatoes",
        name_ar: "دجاج وطماطم مجففة",
        description_en: "",
        price: "29",
        img_path: "/five-am-menu-designs/sandwiches/06 Chicken Sundried Tomatoes_WEB.jpg"
    },

    {
        name_en: "Beef Burger",
        name_ar: "برغر لحم",
        description_en: "",
        price: "58",
        img_path: "/five-am-menu-designs/sandwiches/07 Beef Burger_WEB.jpg"
    },

]

export default sandwiches;