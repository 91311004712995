import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {Box, Drawer, Button, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText}  from "@mui/material";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark, faGrip, faPlus, faPenToSquare  } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {SearchDashboard, AddDashboard, EditDashboard, OrganizeDashboard } from "./components"



//configure anything related to the restaurant in here

function RestaurantsDashboard({}) {

    {/* ALL THE CONSTANTS GO HERE */}
    const {name: restaurantName, section} = useParams();
    const url = "https://www.lovelysunnydayapi.com/api/v1/testMenus"; //change this url based on the restaurant!!
    //const [grabbedItems, setGrabbedItems] = useState([]); //add separately search, edit and organize tabs
    //const [item, setItem] = useState({}); //add separately to add, edit and organze tabs
    const [activeDashboard, setActiveDashboard] = useState("Search");
    const [triggerToolTip, setTriggerToolTip] = useState({
        editTool: false,
        deleteTool: false,
        hideTool: false,
    })
    const drawerWidth =  240;
    const drawerList = [
        {
            "name": "Search",
            "icon": faMagnifyingGlass,
        },

        {
            "name": "Add",
            "icon": faPlus,
        },

        {
            "name": "Edit",
            "icon": faPenToSquare,
        },

        {
            "name": "Organize",
            "icon": faGrip,
        },
    ]

    //function that activates the active dashboard component
    function handleActiveBoard(name) {
        setActiveDashboard(name);
    }

    return (
        <DashboardPageStyled>
            <DrawerContainerStyled>
               <Drawer
                sx = {{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant = "permanent"
                anchor = "left"
               >
                    <List>
                       {drawerList.map(({name, icon}, index) => {
                        return (
                            <ListItem key = {name} disablePadding>
                                <ListItemButton onClick = { () => handleActiveBoard(name)}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon = {icon} style = {{fontSize: "20px", padding: "0px"}}/>
                                    </ListItemIcon>
                                    <ListItemText primary = {name}/>
                                </ListItemButton>
                            </ListItem>
                        )
                       })}
                    </List> 
               </Drawer>
            </DrawerContainerStyled>
            <MainContainerStyled>
                {/* trigger Dashboard here*/}
                { activeDashboard == "Search" && 
                    <SearchDashboard 
                        url = {url}
                        restaurantName = {restaurantName}
                        activeDashboard = {activeDashboard}
                        triggerToolTip = {triggerToolTip} //only for search, edit and organize
                        setTriggerToolTip = {setTriggerToolTip} //only for search, edit and organize
                    />}
                { activeDashboard == "Add" && 
                    <AddDashboard
                       url = {url}
                       restaurantName = {restaurantName}
                       activeDashboard = {activeDashboard}
                    />}
                { activeDashboard == "Edit" && 
                    <EditDashboard
                        url = {url}
                        restaurantName = {restaurantName}
                        activeDashboard = {activeDashboard}
                        triggerToolTip = {triggerToolTip}
                        setTriggerToolTip = {setTriggerToolTip}
                    />}
                { activeDashboard == "Organize" && <OrganizeDashboard/>}
            </MainContainerStyled>
        </DashboardPageStyled>
    )
}


const DashboardPageStyled = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
`

const DrawerContainerStyled =styled.div`
    position: relative;
`

const MainContainerStyled =styled.div`
    position: relative;
    width: 100%;
    padding: 25px;
`

/* Category Flex Styles go here */




export default RestaurantsDashboard;