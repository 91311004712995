//{/* MAIN FORM COMPONENT AS PARENT, CHILDREN WILL BE FORMS OF EACH RESTAURANT */}

import { LeBleuForm, WCSForm } from "./";
import { EditFormStyled, FormContainerStyled, GeneralButtonStyled } from "../generalStyles";
import { handleSubmit } from "../../functions/generalFunctions";
import {useState, useEffect, useRef} from "react";
import { Button } from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";

//the parent component will submit the form
function EditForm({restaurantName, item, activeDashboard}) {

    const [formData, setFormData] = useState({});
    const [fieldsErrors, setFieldsErrors] = useState({});
    const [triggerFieldError, setTriggerFieldError] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [tempImage, setTempImage] = useState(""); //link of the temporarily image that will be displayed as user changes images
    const [loadAnimation, setLoadAnimation] = useState(false); //triggers the load animation
    const fileInputRef = useRef(null); //reference to the image file input in forms
    // var characterCount = `${formData.description_en.length}/200`
    // var characterCountAr = `${formData.description_ar.length}/200`

    useEffect(() => {
        console.log(item);
        setFormData(item);
    }, [])
    
    
    return (
        <EditFormStyled onSubmit = {(e) => handleSubmit(e,setFieldsErrors, setTriggerFieldError, formData, activeDashboard)}>
            <FormContainerStyled>
               {restaurantName == "le-bleu" && 
                    <LeBleuForm
                        formData = {formData}
                        setFormData = {setFormData}
                        setFieldsErrors = {setFieldsErrors}
                        setTriggerFieldError = {setTriggerFieldError}
                        triggerFieldError = {triggerFieldError}
                        fieldsErrors = {fieldsErrors}
                        setCategoryList = {setCategoryList}
                        tempImage = {tempImage}
                        setTempImage = {setTempImage}
                        loadAnimation = {loadAnimation}
                        setLoadAnimation = {setLoadAnimation}
                        fileInputRef = {fileInputRef}
                    />}
                {restaurantName == "white-coffee-spot" && <WCSForm/>}
                <ThemeProvider theme = {GeneralButtonStyled}>
                    <Button
                        type = "submit"
                        variant = "contained"
                        color = "primary"
                    >
                        submit
                    </Button>
            </ThemeProvider>
            </FormContainerStyled>
        </EditFormStyled>
    )
}

export default EditForm;