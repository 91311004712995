import styled from "styled-components";

function EggsEn() {
    return (
        <>
            <div>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- koulouri.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>Koulouri Poached eggs <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>on seasame bagel with greek yogurt & spicy oil</TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>65 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- scrambled brioche.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>Scrambled eggs on brioche <br/>
                        <ItemDescriptionParStyled>
                           <TextItalicBoxStyled>cream cheese, truffle paste, avocado, sauted spinach,
                                mushrooms, chives
                           </TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>67 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- greek omlette.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Greek salad omelette <br/>
                            <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>cherry tomatos, feta cheese, gruyer cheese, kalamata olives, grilled capsicum</TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>51 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- vegetables omlette.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Vegetables omelette <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>mushrooms, cheery tomatos, feta cheese, cheddar cheese, grilled capsicum</TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>51 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- croque madame.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Croque-madame<br/> 
                            <TextItalicBoxStyled>on brioche, sunny side up egg with mayo, gruyere sauce & smoked turkey</TextItalicBoxStyled>
                        </p>
                        <PriceBoxStyled>61 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- scrambled eggs bacon.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Scrambled eggs on sourdough toast <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>with avocado & beef bacon</TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>59 QR</PriceBoxStyled>
                        <p>
                            <TextItalicBoxStyled>or avocado & salmon</TextItalicBoxStyled>
                        </p>
                        <PriceBoxStyled style = {{fontSize: "12px", fontStyle: "italic"}}>+ 8 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- avocado toast poached.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Avocado toast poached eggs <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>cheddar, beef bacon or turkey & mayo</TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>63 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- soutzouki eggs.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Soutzouki Eggs <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>spicy beef salami cooked in red pepper &
                                    tomato sauce with feta cheese
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>59 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- sourdough eggs salmon.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Sourdough croissant with scrambled eggs <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>& smoked salmon
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>59 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Eggs Pics/Croissant-Roll-Cheese_WEB.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Croissant roll cheese <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                Truffle cheese, sauté mushrooms & spinach, scrambled eggs, truffle oil, truffle carpaccio
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>57 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
            </div>
        </>
    )
}

function EggsAr() {
    return (
        <>
            <div>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- koulouri.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>كولوري مع بيض مسلوق <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>بالسمسم
مع زبادي يوناني والزيت الحار</TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٦٥ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- scrambled brioche.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>بيض مخفوق على خبز البريوش<br/>
                        <ItemDescriptionParArStyled>
                           <TextItalicBoxStyled>جبنة الكريمة، صلصة الكمة، أفوكادو
سبانخ مقلية، فطر، ثوم معمر 
                           </TextItalicBoxStyled>
                           </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٦٧ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- greek omlette.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        أومليت السلطة اليونانية <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>طماطم كرزية، جبنة فيتا، يتون، فلفل حلو مشوي
جبنة الغروير </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٥١ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- vegetables omlette.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        أومليت الخضار <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>الفيتا
جبنة الشيدر، فلفل حلو مشوي </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٥١ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- croque madame.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        كروك مدام<br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            على لفائف البريوش، بيض عيون مقلي مــع مايونيز، صلصة غرويير وديك رومي مدخن
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٦٥ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- scrambled eggs bacon.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        بيض مخفوق على خبز محمص <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>مع أفوكادو ولحم بقري مقدد</TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٥٩ ر.ق</PriceBoxStyled>
                        <p>
                            <TextItalicBoxStyled>أو أفوكادو وسمك السالمون</TextItalicBoxStyled>
                        </p>
                        <PriceBoxStyled style = {{fontSize: "12px", fontStyle: "italic"}}>٨ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- avocado toast poached.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        توست أفوكادو مع بيض مسلوق <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>شيدر، لحم بقري مقدد أو ديك رومي ومايونيز</TextItalicBoxStyled>
                            </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٦٣ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- soutzouki eggs.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        بيض السوتسوكي <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>مع لحم بقري حار مطهو بالفلفل الأحمر و صلصة الطماطم مع جبنة فيتا
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٥٩ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/eggs- sourdough eggs salmon.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        كرواسان الساوردو مع بيض مخفوق <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>وسمك السالمون المدخن
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٥٩ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Eggs Pics/Croissant-Roll-Cheese_WEB.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        كرواسون مع كريمة الكماة<br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>جبنة الكماة، فطر وسبانخ مقلية، بيض مخفوق، زيت الكماة، والكماة بالزيت
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٥٧ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
            </div>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
    /* img {
        width: 60%;
    } */
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`
    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`


export {
    EggsEn,
    EggsAr,
};