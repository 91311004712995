import { SliderContainerStyled, NavBarLayerOneStyled, NavBarOneTextStyled } from "./generalStyles";
import {useEffect, useState, useRef} from "react";

function NavigationBar({resturantName, setGrabbedItems, categoryList}) {

    const sliderContainer = useRef(null);
    const testList = ["itemone", "item two", "item 3", "item 4", "item 5", "item 6", "item 7", "item 8", "item 9", ]
    const categoryRef = useRef([]);

    //triggers the active category
    function handleActiveCategory(item, current_index) {
     
        categoryRef.current[current_index].style.color = "#00abd1"; //activates the category

        //deactive all the other categories
        testList.map((name, index) => {
            if(index != current_index) {
                categoryRef.current[index].style.color = "black";
            }
        })

        //api call to fetch items with that category
        console.log(item);
    }


    return (
        <>
            <SliderContainerStyled ref = {sliderContainer}>
                <NavBarLayerOneStyled>
                    {testList.map((item, index) => {
                        return (
                            <NavBarOneTextStyled ref = {(el) => categoryRef.current[index]=el} onClick = {()=> handleActiveCategory(item, index)}>
                                <span>{item}</span>
                            </NavBarOneTextStyled>
                        )
                    })}
                </NavBarLayerOneStyled>
            </SliderContainerStyled>
        </>
    )
}

export default NavigationBar;
