import styled from "styled-components";

function HomeMadePiesEn({}) {
    return (
        <>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Homemade Pics/homeMade- cheese pie.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Cheese pie
                    </p>
                    <PriceBoxStyled>31 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Homemade Pics/homeMade- spinach & feta cheese.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Spinach & feta cheese pie
                    </p>
                    <PriceBoxStyled>31 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Homemade Pics/homeMade- sweet milk cream.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Sweet milk cream Bougatsa pie
                    </p>
                    <PriceBoxStyled>31 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
        </>
    )
}

function HomeMadePiesAr({}) {
    return (
        <>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Homemade Pics/homeMade- cheese pie.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    فطيرة جبنة
                    </p>
                    <PriceBoxStyled>٣١ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Homemade Pics/homeMade- spinach & feta cheese.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    فطيرة سبانخ وجبنة فيتا
                    </p>
                    <PriceBoxStyled>٣١ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Homemade Pics/homeMade- sweet milk cream.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    فطيرةالبوغاتسا مع كريمة الحليب المحلى
                    </p>
                    <PriceBoxStyled>٣١ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`



    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`
export {
    HomeMadePiesEn,
    HomeMadePiesAr,
};