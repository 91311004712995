const tea = [
    {
        name_en: "English Breakfast Tea ",
        description_en: " ",
        name_ar: "إنغلش بريكفاست تي ",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/01 English Breakfast_WEB.jpg"
    },

    {
        name_en: "Chamomile ",
        description_en: " ",
        name_ar: "شاي بابونج ",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/02 Chamomile_WEB.jpg"
    },

    {
        name_en: "Green Tea ",
        description_en: " ",
        name_ar: "شاي أخضر ",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/03 Green tea_WEB.jpg"
    },

    {
        name_en: "Karak ",
        description_en: " ",
        name_ar: "كرك ",
        description_ar: "",
        price: "11",
        img_path: "/five-am-menu-designs/tea/04 Karak_WEB.jpg"
    },

]

export default tea;