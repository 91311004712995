import {useState, useEffect} from "react";
import axios from "axios";
import {ItemContainerStyled, ImgContainerStyled, ItemEnglishTitleStyled, ItemEnglishDescriptionStyled, PriceContainerStyled, ItemButtonsContainerStyled, ItemButtonStyled, PopTextStyled, PopTextCloseStyled, ClosePopUpStyled, ClosePopButtonStyled} from "./generalStyles";
import { faMagnifyingGlass, faXmark, faGrip, faPlus, faPenToSquare, faEyeSlash, faArrowsToEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {Grid, Button, Drawer} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles";
import { handleToolTip, handleHideItem } from "../functions/generalFunctions";
import EditFrom from "./editForms/EditForm";


function SearchDashboard({url, restaurantName, triggerToolTip, setTriggerToolTip, activeDashboard}) {

    {/* ALL CONSTANSTS AND HOOKS GO HERE */}
    const [searchQuery, setSearchQuery] = useState("");
    const [grabbedItems, setGrabbedItems] = useState([]);
    const [item, setItem] = useState({});
    const [removeQuery, setRemoveQuery] = useState(false);
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [itemDelete, setItemDelete] = useState("");
    const [itemID, setItemID] = useState("");
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
  

    {/* ALL FUNCTIONS GO HERE */}
    //function that changes the searchQuery state as we search for the item
    function handleSearchQuery(event) {
        setSearchQuery(event.target.value);        
    }

    //grabs the items from the database
    async function handleSearchForm() {
        if(searchQuery != "") {
            const {data} = await axios.get(`${url}?search=${searchQuery}`, {
                withCredentials: true,
            })
            if(data.items.length == 0) {
                console.log("item doesn't exist!") //add it as a pop up or something later
            }
            else {
                setGrabbedItems(data.items);
            }
        }
        else {
            // console.log(data.items);
            return;
        }
     
    }

    //function handle closeQueryButton {
    function handleCloseQueryButton() {
        /* delete the list of the searched items */
        setSearchQuery("");
        setGrabbedItems([]);
        setRemoveQuery(false);
    }
    
    {/* THE FUNCTIONS BELOW SHOULD BE UNIVERSAL */}
    //this function will trigger a pop up edit form to edit the chosen item
    function handleEditItem(_id) {
        //trigger drawer
        setOpenEditDrawer(true);
        //from the grabbedItems array add the chosen item.
        setItem(grabbedItems.find((item) => item._id === _id))
    }

  

    //function to trigger the pop up screen of the corresponding item (for deleting the item)
    function handleDeletePopUp(_id, itemName) {
        setPopUpOpen(true);
        setItemDelete(itemName);
        setItemID(_id);
    }
    
    //this function closes the pop up screen if we dont delete (Related to upper function)
    function handleClosePopUp() {
        setPopUpOpen(false);
    }

    async function handleDeleteItem() {
        //function to call api and delete item
    }
    return (
        <>
            {popUpOpen && <ClosePopUpStyled>
                    Are you sure you want to delete {itemDelete}
                        <Button
                            variant = "contained"
                            color = "primary"
                            onClick = {handleDeleteItem}
                        >
                            YES
                        </Button>
                    <ClosePopButtonStyled 
                        onClick = {handleClosePopUp}
                        onMouseEnter={() => handleToolTip(true, "close", setTriggerToolTip)}
                        onMouseLeave={() => handleToolTip(false, "close", setTriggerToolTip)}
                    >
                        <FontAwesomeIcon icon = {faXmark} style = {{fontSize: "20px", padding: "0"}}>
                        </FontAwesomeIcon>
                    </ClosePopButtonStyled>
            </ClosePopUpStyled>}
            <SearchBoxWrapperStyled>
                <SearchBoxContainerStyled>
                    <SearchTextFieldStyled placeholder = "Seach for item..."  onChange = {handleSearchQuery} value = {searchQuery}/>
                    { searchQuery && <CloseSearchContainerStyled onClick = {handleCloseQueryButton}>
                        <FontAwesomeIcon icon  = {faXmark} style = {{fontSize: "20px", paddingRight: "0px", color: "grey"}}/>
                    </CloseSearchContainerStyled>}
                </SearchBoxContainerStyled>
                <SearchButtonStyled onClick = {handleSearchForm}>
                    <FontAwesomeIcon icon = {faMagnifyingGlass} style = {{fontSize: "20px", paddingRight: "0px"}} />
                </SearchButtonStyled>
            </SearchBoxWrapperStyled>
            <GridContainerStyled>
                    <Drawer open = {openEditDrawer} anchor = "right" onClose = {() => setOpenEditDrawer(false)} style = {{width: "400px"}}>
                        <EditFrom restaurantName = {restaurantName} item = {item} activeDashboard = {activeDashboard}/>
                    </Drawer>
                    <Grid container spacing = {2}>
                        {grabbedItems.length !=0 && grabbedItems.map(({_id,name_en, description_en, image, price }) => {
                            return (
                                <Grid item xs = {4}>
                                    <ItemContainerStyled>
                                        <ItemButtonsContainerStyled>
                                            <ItemButtonStyled
                                                onMouseEnter = {() => handleToolTip(true, "hide", setTriggerToolTip)}
                                                onMouseLeave = {() => handleToolTip(false, "hide", setTriggerToolTip)}
                                                onClick = {() => handleHideItem(url, _id, true, grabbedItems)}
                                            >
                                                {triggerToolTip.hideTool && <PopTextStyled className="animate__animated animate__fadeInUp animate__faster">hide</PopTextStyled>}
                                                <FontAwesomeIcon icon = {faEyeSlash} style = {{fontSize: "20px", padding: "0"}}/>
                                            </ItemButtonStyled>
                                            <ItemButtonStyled
                                                onMouseEnter = {() => handleToolTip(true, "edit", setTriggerToolTip)}
                                                onMouseLeave = {() => handleToolTip(false, "edit", setTriggerToolTip)}
                                                onClick = {() => handleEditItem(_id)}
                                            >
                                                {triggerToolTip.editTool && <PopTextStyled className="animate__animated animate__fadeInUp animate__faster">edit</PopTextStyled>}
                                                <FontAwesomeIcon icon = {faPenToSquare} style = {{fontSize: "20px", padding: "0"}}/>
                                            </ItemButtonStyled>
                                            <ItemButtonStyled 
                                                onClick = {() => handleDeletePopUp(_id, name_en)}
                                                onMouseEnter = {() => handleToolTip(true, "delete", setTriggerToolTip)}
                                                onMouseLeave = {() => handleToolTip(false, "delete", setTriggerToolTip)}
                                            >
                                                {triggerToolTip.deleteTool && <PopTextStyled className = "animate__animated animate__fadeInUp animate__faster" >delete</PopTextStyled>}
                                                <FontAwesomeIcon icon = {faTrashCan}  style = {{fontSize: "20px", padding: "0px"}}/>
                                            </ItemButtonStyled>
                                        </ItemButtonsContainerStyled>
                                        <ImgContainerStyled>
                                            <img src = {image} alt = {name_en}/>
                                        </ImgContainerStyled>
                                        <ItemEnglishTitleStyled>
                                            {name_en}
                                            <PriceContainerStyled>
                                                {price}
                                                <span>QR</span>
                                            </PriceContainerStyled>
                                        </ItemEnglishTitleStyled>
                                        <ItemEnglishDescriptionStyled>
                                            {description_en}
                                        </ItemEnglishDescriptionStyled>
                                    </ItemContainerStyled>
                                </Grid>
                            )
                        })}
                    </Grid>
                </GridContainerStyled>
        </>
    )
}


const SearchBoxWrapperStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
`
const SearchBoxContainerStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    border: 2px solid rgba(128, 128, 128, 0.4);
    border-right-width: 0;
    box-sizing: border-box;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    overflow: hidden; //ensures content stays within the border
    cursor: pointer;
    padding: 20px;
    gap: 10px;
    transition: all 300ms;

    //add react conditional activate later when u click on it to search to change borde to
    // border: 2px solid rgba(128 ,128, 128, 1);
`

const SearchButtonStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: 2px solid grey;
    border-left-width: 0px;
    border-left: 2px solid black;
    opacity: 0.4;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

`

const SearchTextFieldStyled = styled.input`
    position: relative;
    outline: 0;
    border: 0;
    width: 100%;
`

const CloseSearchContainerStyled = styled.div`
    position: relative;
    margin-left: auto;
   
`

const GridContainerStyled = styled.div`
    position: relative;
    margin-top: 50px;
`

export default SearchDashboard;