const sandwichBoxes = [
    {
        name_en: "AM Box - 12 Pieces ",
        description_en: " Egg & cheese, chicken liver, shakshouka, labneh & makdous, halloumi, feta (2 pieces each)",
        name_ar: "علبة أي أم - ١٢ قطعة",
        description_ar: " البيض والجبن، الكبد، شكشوكة، ومكدوس، حلومي، فيتا",
        price: "99",
        img_path: "/five-am-menu-designs/sandwichBoxes/Five AM - Am Box.jpg"
    },

    {
        name_en: "PM Box - 12 Pieces ",
        description_en: " chicken, egg & salmon, kafta, turkey & cheese, chicken sundried tomatoes, egg & avocado (2 pieces each)",
        name_ar: "صندوق بي أم - ١٢ قطع ",
        description_ar: " دجاج، بيض وسلمون، كفتة، ديك رومي وجبن، دجاج وطماطم مجففة، بيض وأفوكادو",
        price: "145",
        img_path: "/five-am-menu-designs/sandwichBoxes/Five AM - Pm Box.jpg"
    },

    {
        name_en: "All Day Box - 24 Pieces ",
        description_en: "egg & cheese, chicken liver, shakshouka, labneh & makdous, halloumi, feta, chicken, egg & salmon, kafta, turkey & cheese, chicken sundried tomatoes, egg & avocada (2 pieces each)",
        name_ar: "صندوق طوال اليوم ",
        description_ar: " البيض والجبن، كبدة، شكشوكة، لبنة ومكدوس، حلومي، فيتا، دجاج، البيض والسلمون، كفتة، حبش والجبن، دجاج بالطماطم المجففة، البيض والأفوكادو",
        price: "222",
        img_path: "/five-am-menu-designs/sandwichBoxes/Five AM - All Day Box.jpg"
    },
]

export default sandwichBoxes;