const sugar = [
    {
        name_en: "Basbusa with Carmelized Popcorn",
        name_ar: "بسبوسة مع فشار مكرمل وايس كريم ",
        description_en: "",
        description_ar: "",
        price: "33",
        img_path: "/five-am-menu-designs/Sugar Rush/01 Basbusa with Carmelized Popcorn_WEB.jpg"
    },

    {
        name_en: "Date Pudding with Tahini Ice Cream",
        name_ar: "بودينغ التمر مع طحينية ايس كريم ",
        description_en: "",
        price: "36",
        img_path: "/five-am-menu-designs/Sugar Rush/02 Date Pudding with Tahini Icecream_WEB.jpg"
    },

    {
        name_en: "Pancakes Berries Mascarpon ",
        description_en: "",
        name_ar: "فطائر التوت بالماسكربون ",
        price: "59",
        img_path: "/five-am-menu-designs/Sugar Rush/03 Pancakes Berries_WEB.jpg"
    },

    {
        name_en: "Babka Chocolate ",
        description_en: "",
        name_ar: "بابكا بالشوكولاتة ",
        price: "39",
        img_path: "/five-am-menu-designs/Sugar Rush/04 Babka Chocolate_WEB.jpg"
    },

    {
        name_en: "Babka Cinnamon ",
        description_en: "",
        name_ar: "بابكا بالقرفة ",
        price: "39",
        img_path: "/five-am-menu-designs/Sugar Rush/05 Babka Cinnamon_WEB.jpg"
    },

    {
        name_en: "Belgian Waffle ",
        description_en: "",
        name_ar: "وافل بلجيكي ",
        price: "30",
        img_path: "/five-am-menu-designs/Sugar Rush/06 Belgian Waffle_WEB.jpg"
    },

    {
        name_en: "24 White Layer Cake ",
        description_en: "",
        name_ar: "كعكة بيضاء ",
        price: "60",
        img_path: "/five-am-menu-designs/Sugar Rush/07 24 White Layer Cake_WEB.jpg"
    },

    {
        name_en: "24 Chocolate Layer Cake ",
        description_en: "",
        name_ar: "كعكة شوكولاتة ٢٤ طبقة ",
        price: "60",
        img_path: "/five-am-menu-designs/Sugar Rush/08 24 Chocolate Layer Cake_WEB.jpg"
    },

]

export default sugar;