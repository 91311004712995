import EditForm from "./editForms/EditForm";
import { useEffect, useState } from "react";
import { leBleuItem } from "../data/LeBleu";
function AddDashboard({url, restaurantName, activeDashboard}) {

    const [item, setItem] = useState({})

    useEffect(() => {
        switch(restaurantName) {
            case "le-bleu":
                setItem(leBleuItem);
            break;

            // case "white-coffee-spot":
            //     setItem()
            // break;
        }
    }, [])


    return (
       
        <>  
            {Object.keys(item).length > 0 && 
                <EditForm 
                    restaurantName={restaurantName} 
                    item = {item} 
                    activeDashboard = {activeDashboard}
                />}
        </>
    )
}

export default AddDashboard;