import styled from "styled-components";

function CoffeeEn({}) {
    return (
        <>
            <CategoryHeadingStyled>
                    COFFEE
            </CategoryHeadingStyled>
            <FlexBoxContainerStyled>
                <TextBoxStyled>
                    <span>Espresso <strong>18 QR</strong> / Double <strong>20 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Cappuccino <strong>24 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Latte <strong>24 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Macchiato <strong>18 QR</strong> / Double <strong>20 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Flat White <strong>24 QR</strong> / Mocca <strong>27 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Spanish Latte (Hot or Cold) <strong>29 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Freddo Espresso <strong>22 QR</strong> / Freddo Cappuccino <strong>29 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Cortado <strong>24 QR</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerStyled>
            <CategoryHeadingStyled style = {{marginTop: "100px"}}>
                    SINGLE ESTATE POUR OVER
            </CategoryHeadingStyled>
            <FlexBoxContainerStyled>
                <TextBoxStyled>
                    <span>Cold Brew <strong>25 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>V60 <strong>24 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Chemex (For two) <strong>38 QR</strong></span>
                    <br/>
                    <span>(Ask about our single estate coffees)</span>
                </TextBoxStyled>
            </FlexBoxContainerStyled>
            <CategoryHeadingStyled style = {{marginTop: "100px"}}>
                    SMOOTHIES
                    <div style = {{fontSize: "13px"}}>
                        <span>blended with Greek yogurt & honey</span>
                    </div>
            </CategoryHeadingStyled>
            <FlexBoxContainerStyled>
                <TextBoxStyled style = {{letterSpacing: "0px", fontSize: "10px"}}>
                        <span>Strawberry, raspberry, blueberry, apple, banana <strong style = {{paddingLeft: "5px"}}> 32 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled style = {{letterSpacing: "0px", fontSize: "10px"}}>
                        <span>Papaya, pineapple, raspberry, banana<strong style = {{paddingLeft: "5px"}}> 32 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled style = {{letterSpacing: "0px", fontSize: "10px"}}>
                        <span>Strawberry, kiwi, raspberry, apple, banana<strong style = {{paddingLeft: "5px"}}> 32 QR</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerStyled>
            <CategoryHeadingStyled style = {{marginTop: "100px"}}>
                    FRESH JUICES
            </CategoryHeadingStyled>
            <FlexBoxContainerStyled>
                <TextBoxStyled>
                <span>Homemade lemonade with ginger <strong style = {{paddingLeft: "5px"}}> 27 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>Freshly squeezed orange juice <strong style = {{paddingLeft: "5px"}}> 25 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>Pear, apple & kiwi juice <strong style = {{paddingLeft: "5px"}}> 28 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>Carrot, ginger & apple juice <strong style = {{paddingLeft: "5px"}}> 28 QR</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerStyled>
            <CategoryHeadingStyled>
                    Mocktails
            </CategoryHeadingStyled>
            <FlexBoxContainerStyled>
                <TextBoxStyled>
                    <span>Strawberry Mojito <strong>29 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Passion Fruit Mojito <strong>29 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Classic Mojito <strong>28 QR</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerStyled>
            <CategoryHeadingStyled style = {{marginTop: "100px"}}>
                    BEVERAGES
            </CategoryHeadingStyled>
            <FlexBoxContainerStyled>
                <TextBoxStyled>
                <span>Chocolate (Hot or Cold) <strong> 26 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>Greek Coffee <strong> 20 QR</strong> / Double <strong> 22 QR </strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>Ergon Tea <strong>18 QR</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerStyled>
            <CategoryHeadingStyled style = {{marginTop: "100px"}}>
                    SOFT DRINKS
            </CategoryHeadingStyled>
            <FlexBoxContainerStyled>
                <TextBoxStyled>
                <span>Fanta Orangeade / Strawberry 250ml <strong> 15 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>Sprite 250ml <strong> 15 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>Coca cola / Coca cola light <strong> 15 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>Still water 250ml <strong>12 QR</strong> /  750ml <strong>18 QR</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>Sparkling water 250ml <strong>12 QR</strong> / 750ml <strong>18 QR</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerStyled>
        </>
    )
}

function CoffeeAr({}) {
    return (
        <>
            <CategoryHeadingArStyled>
            القهوة
            </CategoryHeadingArStyled>
            <FlexBoxContainerArStyled>
                <TextBoxStyled>
                    <span> إسبرسو <strong>١٨ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> كابتشينو<strong> ٢٤ ر.ق</strong> / دبل <strong> ٢٥ ر.ق</strong> </span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> لاتيه <strong>٢٤ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> ماكياتو <strong>١٨ ر.ق</strong> / دبل <strong> ٢٠ ر.ق</strong> </span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> فلات وايت <strong>٢٤ ر.ق</strong> / موكا <strong>٢٧ ر.ق</strong> </span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> سبانش لاتيه (ساخنة أو باردة) <strong>٢٩ ر.ق</strong> </span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> فريدو اسبريسو <strong>٢٢ ر.ق</strong> / فريدو كابتشينو <strong>٢٩ ر.ق</strong> </span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> كورتادو <strong>٢٤ ر.ق</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerArStyled>
            <CategoryHeadingArStyled style = {{marginTop: "100px"}}>
            بور اوفر
            </CategoryHeadingArStyled>
            <FlexBoxContainerArStyled>
                <TextBoxStyled>
                    <span> قهوة باردة <strong>٢٥ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> ڤي ٦٠ <strong>٢٤ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>كيميكس (لشخصين) <strong>٣٨ ر.ق</strong></span>
                    <br/>
                    <span>(إسأل عن قهوتنا اليومية المختصة)  </span>
                </TextBoxStyled>
            </FlexBoxContainerArStyled>
            <CategoryHeadingArStyled style = {{marginTop: "100px"}}>
            سموثيز
                    <div style = {{fontSize: "13px", fontFamily: "Gotham Greek Book"}}>
                        <span>ممزوج الزبادي اليوناني والعسل</span>
                    </div>
            </CategoryHeadingArStyled>
            <FlexBoxContainerArStyled>
                <TextBoxStyled>
                    <span> فراولة، توت العليق، توت ازرق، تفاح، موز <strong>٣٢ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> بابايا، أناناس، توت العليق، موز <strong>٣٢ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> فراولة، كيوي، توت العليق، تفاح، موز <strong>٣٢ ر.ق</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerArStyled>
            <CategoryHeadingArStyled style = {{marginTop: "100px"}}>
            عصائر طازجة
            </CategoryHeadingArStyled>
            <FlexBoxContainerArStyled>
                <TextBoxStyled>
                    <span>عصير الليمون محلي الصنع مع الزنجبيل <strong>٢٧ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> عصير البرتقال الطازج<strong>٢٥ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> عصير الكمثرى والتفاح والكيوي <strong>٢٨ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> الجزر والزنجبيل وعصير التفاح <strong>٢٨ ر.ق</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerArStyled>
            <CategoryHeadingArStyled style = {{marginTop: "100px"}}>
            موكتيل
            </CategoryHeadingArStyled>
            <FlexBoxContainerArStyled>
                <TextBoxStyled>
                    <span> موهيتو فراوله<strong>٢٩ ر.ق </strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> موهيتو باشن فروت<strong>٢٩ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> موهيتو كلاسيك<strong>٢٨ ر.ق</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerArStyled>
            <CategoryHeadingArStyled style = {{marginTop: "100px"}}>
            مشروبات اخرى
            </CategoryHeadingArStyled>
            <FlexBoxContainerArStyled>
                <TextBoxStyled>
                    <span>هوت شوكليت (ساخنة أو باردة) <strong>٢٦ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>  قهوة يونانية <strong>٢٠ ر.ق</strong> / دبل <strong>٢٢ ر.ق</strong> </span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>شاي إرقون <strong> ١٨ ر.ق</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerArStyled>
            <CategoryHeadingArStyled style = {{marginTop: "100px"}}>
            مشروبات باردة
            </CategoryHeadingArStyled>
            <FlexBoxContainerArStyled>
                <TextBoxStyled>
                <span>فانتا برتقال \ فراولة ٢٥٠مل <strong>١٥ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                <span>سبرايت ٢٥٠مل <strong> ١٥ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>كوكا كولا \ كوكا كولا دايت <strong> ١٥ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> <span> مياه معدنية  ٢٥٠مل </span><strong>١٢ ر.ق</strong><span>  مياه معدنية  ٧٥٠ مل</span> <strong> ١٨ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span> <span> مياه غازية  ٢٥٠مل</span><strong> ١٢ ر.ق</strong><span>  مياه غازية  ٧٥٠ مل </span> <strong>١٨ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>مياه غازية بالمستكة  ٢٥٠مل <strong> ٢٥ ر.ق</strong></span>
                </TextBoxStyled>
                <TextBoxStyled>
                    <span>مياه غازية بالمستكة والليمون  ٢٥٠مل <strong>٢٥ ر.ق</strong></span>
                </TextBoxStyled>
            </FlexBoxContainerArStyled>
        </>
    )
}

const FlexBoxContainerStyled = styled.div`
    position: relative;
    font-family: "Gotham Greek Book";
    font-size: 11px;
`

const FlexBoxContainerArStyled = styled(FlexBoxContainerStyled)`
    font-family: "GE SS Text";
    text-align: right;
`

const CategoryHeadingStyled = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    font-size: 15px;
    font-family: "Gotham Greek Book";
    text-align: center;
    font-weight: bold;
`
const CategoryHeadingArStyled = styled(CategoryHeadingStyled)`
    font-family: "GE SS Text";

`

const TextBoxStyled = styled.div`
    position: relative;
    margin-bottom: 29px;
`
export {
    CoffeeEn,
    CoffeeAr,
};