


import { Grid } from "@mui/material"

function AppetizersGrid() {
    return (
        <Grid container spacing = {0.1}>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Menu-Title-JPG/Menu-Title-07.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Appetizers JPG/SEK-Fries.WEB.jpg"/>
                </div>
            </Grid>
        </Grid>
    )
}

export default AppetizersGrid;