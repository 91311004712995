const am = [
    {
        name_en: "Fatteh Fried Nest ",
        name_ar: " فتة حمص واللحم المقلية",
        description_en: " Meat, Chickpeas, Yogurt",
        description_ar: " لحم، حمص، لبن",
        price: "22",
        img_path: "/five-am-menu-designs/AM/01 Fattah Fried Nest_WEB.jpg"
    },

    {
        name_en: "Avocado Walnut Butter Toast ",
        name_ar: "توست الافوكادو وزبدة الجوز ",
        description_en: " Muhammara Paste, Walnuts, Avocado, Spiced Cherry Tomato",
        description_ar: "جبن مشكل، معجون افوكادو، طماطم كرزية متبله ",
        price: "21",
        img_path: "/five-am-menu-designs/AM/02 Avocado Walnut Butter Toast_WEB.jpg"
    },

    {
        name_en: "Muhammara & Portabello Toast ",
        name_ar: "توست محمرة وفطر البورتابيلو بالبيض ",
        description_en: " Poached Egg & Hollandaise",
        description_ar: " بيض مسلوق وهولنديز",
        price: "26",
        img_path: "/five-am-menu-designs/AM/03 Muhammara & Portobello Toast_WEB.jpg"
    },

    {
        name_en: "Mini Omelette ",
        name_ar: "أوملت صغير ",
        description_en: " Avocado & Spiced Cherry Tomato ",
        description_ar: " ",
        price: "19",
        img_path: "/five-am-menu-designs/AM/04 Mini Omelette_WEB.jpg"
    },

    {
        name_en: "Folded Egg ",
        name_ar: "بيض مطوي ",
        description_en: " Potato & Button Mushroom, Avocado",
        description_ar: " الآ فوكادو والطماطم الكرز المتبل",
        price: "19",
        img_path: "/five-am-menu-designs/AM/05 Folded Egg_WEB.jpg"
    },

    {
        name_en: "Egg & Cheese ",
        name_ar: "بيض وجبن ",
        description_en: " Eggs, Cream Cheese, Salt, Aleppo Pepper",
        description_ar: " بيض، جبنة كريمية، ملح، فلفل حلبي",
        price: "18",
        img_path: "/five-am-menu-designs/AM/06 Egg and Cheese_WEB.jpg"
    },

    {
        name_en: "Tomato & Eggs ",
        name_ar: "الطماطم والبيض ",
        description_en: " Eggs, Tomato Sauce, Bell Pepper Sauce, Salt & Pepper",
        description_ar: " البيض، صلصة الطماطم، صلصة الفلفل الحلو، الملح والفلفل",
        price: "18",
        img_path: "/five-am-menu-designs/AM/07 Tomato and Eggs_WEB.jpg"
    },

    {
        name_en: "Chicken Liver Hamsa ",
        name_ar: "حمسة كبدة دجاج ",
        description_en: " Chicken Liver & Piri Piri Sauce, Mushrooms, Beef Stock, Garlic & Onion",
        description_ar: "كبد الدجاج وصلصة البيري بيري والفطر ومرق اللحم والثوم والبصل ",
        price: "28",
        img_path: "/five-am-menu-designs/AM/08 Chicken Liver Hamse_WEB.jpg"
    },

    {
        name_en: "Chicken Hamsa ",
        name_ar: "حمسة دجاج ",
        description_en: " Chicken Breast, Potato Cubes, Chickpeas, Bell Pepper Sauce, Masala, Chilli Sweet Sauce",
        description_ar: "صدور دجاج، مكعبات بطاطس، حمص، صلصة فلفل حلو ماسالا، صلصة الفلفل الحلو ",
        price: "24",
        img_path: "/five-am-menu-designs/AM/09 Hamsa Chicken_WEB.jpg"
    },

    {
        name_en: "Croissant with Eggs ",
        name_ar: "كرواسون بالبيض ",
        description_en: " Plain Croissant, Scrambeled Eggs, Cheedar Cheese, Mayo Sirarcha Sauce",
        description_ar: "كرواسون سادة، بيض مخفوق، جبنة شيدر،صلصة مايو سيرارشا ",
        price: "27",
        img_path: "/five-am-menu-designs/AM/10 Croissant with Eggs_WEB.jpg"
    },

    {
        name_en: "Scrambeled Eggs Mushroom ",
        name_ar: "بيض مخفوق مع الفطر ",
        description_en: " Eggs, Cream, Portabello Mushrooms, Parmesan Cheese",
        description_ar: "البيض، الكريمة، فطر البورتوبيلو، جبن البارميزان ",
        price: "33",
        img_path: "/five-am-menu-designs/AM/11 Scrambeled Egg Mushroom_WEB.jpg"
    },

    {
        name_en: "Granola ",
        name_ar: "جرانولا ",
        description_en: " Granolla, Greek Yogurt, Compote",
        description_ar: " جرانولا، زبادي يوناني، كومبوت",
        price: "34",
        img_path: "/five-am-menu-designs/AM/12 Granola_WEB.jpg"
    },

    {
        name_en: "Labne ",
        name_ar: "اللبنة ",
        description_en: " Labne, Olive Oil, Aleppo Pepper",
        description_ar: " لبنة، زيت زيتون، فلفل حلبي",
        price: "17",
        img_path: "/five-am-menu-designs/AM/13 Labneh_WEB.jpg"
    },

    {
        name_en: "Cream Cheese ",
        name_ar: "جبنة كريمية ",
        description_en: " Cream Cheese, Olive Oil, Aleppo Pepper",
        description_ar: " جبنة كريمية، زيت زيتون، فلفل حلبي",
        price: "15",
        img_path: "/five-am-menu-designs/AM/14 Cream Cheese_WEB.jpg"
    },

]


export default am;