import styled from "styled-components";
import {useEffect, useState, useRef} from "react";
import {Am, Beverages, Breads, Coffee, Pm, Sugar, Tea} from "../components/MenuGrids/FiveAM";
import { CopyRightStyled } from "../components/MenuGrids/FiveAM/generalStyles";

function FiveAmNew({}) {

    const [triggerLanguage, setTriggerLanguage] = useState("en");
    const sliderContainer = useRef(null);
    const fiveAmCatRef = useRef([]);
    const fiveAmSectionsRef = useRef([]);
    const categoriesIndices = ['0', '1', '2', '3', '4', '5', '6']

    //triggers the arabic/english menu
    function handleTriggerMenu(language) {
        setTriggerLanguage(language)
    }

    //activate the cateogry we click on
    function navClickHandler(id) {
        activeCategoryHandler(id)
    }

    //activates/deactivates the categories that we click on or we are currently on
    function activeCategoryHandler(sectionId) {
        var index = sectionId -1;
        
        fiveAmCatRef.current[index].style.color = "#00abd1";

        categoriesIndices.map((item) => {
            if(item != index) {
                fiveAmCatRef.current[item].style.color = "black";
            }
        })
    }

    //this functiont tracks the user position as he scrolls down the page
    function windowScrollTracker(currentScroll) {
        var sectionFromTop;
        var sectionId;

        //gets the id of the category we are ona s we scroll    
        fiveAmSectionsRef.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;

            if(sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }
        })

        navBarTracker(sectionId);
    }

    async function navBarTracker(sectionId) {
    
        switch(sectionId) {
            //activates the breads cateogry
            case '1':
                // sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the sugar rush cateogory
            case '2':
                // sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the AM category
            case '3':
                // sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the PM category
            case '4':
                // sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;
            //activates the beverages category
            case '5':
                // sliderContainer.current.scrollLeft = 100;
                await activeCategoryHandler(sectionId)
            break;

            //activates the coffee category
            case '6':
                await activeCategoryHandler(sectionId)
            break;

            //activates the tea category
            case '7':
                await activeCategoryHandler(sectionId);
            break;
        }
        return "resolved";
    }

    //function to move the slider
    function useHorizontalScrollEventEn(callback) {
        const positionRef = useRef(0);

        return(e) => {
            const x = e.currentTarget.scrollLeft;
            if(x !== positionRef.current) {
                positionRef.current = x;
                callback(e);
            }
        }
    }

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll);
        }

        var timer = null;

        window.addEventListener('scroll', function() {
            if(timer !== null) {
                clearTimeout(timer)
            }
            timer = setTimeout(function() {
                handleScroll()
            }, 50)
        })
    }, [])

    //changes the scroll bar based on the language triggered
    useEffect(() => {
        if(sliderContainer.current) {
            if(triggerLanguage == "ar") {
                sliderContainer.current.scrollLeft = sliderContainer.current.scrollWidth;
            } else {
                sliderContainer.current.scrollLeft = 0;
            }
        }
    }, [triggerLanguage])

    if(triggerLanguage == "en") {
        return (
            <FiveAmPageStyled>
                <SliderContainerStyled ref = {sliderContainer} onScroll = {useHorizontalScrollEventEn} triggerLanguage = "en">
                    <NavTextStyled>
                     
                        <a href = "#1" ref = {(el) => fiveAmCatRef.current[0]=el} onClick = {()=>navClickHandler("1")}>
                                <span>BREADS & DIPS</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#2" ref = {(el) => fiveAmCatRef.current[1]=el} onClick = {()=>navClickHandler("2")}>
                                <span>SUGAR RUSH</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#3" ref = {(el) => fiveAmCatRef.current[2]=el} onClick = {()=>navClickHandler("3")}>
                                <span>AM</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#4" ref = {(el) => fiveAmCatRef.current[3]=el} onClick = {()=>navClickHandler("4")}>
                                <span>PM</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#5" ref = {(el) => fiveAmCatRef.current[4]=el} onClick = {()=>navClickHandler("5")}>
                                <span>BEVERAGES</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#6" ref = {(el) => fiveAmCatRef.current[5]=el} onClick = {()=>navClickHandler("6")}>
                                <span>COFFEE</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#7" ref = {(el) => fiveAmCatRef.current[6]=el} onClick = {()=>navClickHandler("7")}>
                                <span>TEA</span>
                        </a>
                    </NavTextStyled>
                </SliderContainerStyled>
                <LanguageTriggerContainerStyled triggerLanguage = "en">
                    <span onClick = {() => handleTriggerMenu("ar")}>عربي</span>
                </LanguageTriggerContainerStyled>
                <CategoryContainerStyled>
                    <section id = "1" ref = {(el) => fiveAmSectionsRef.current[0] = el}>
                        <Breads triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "2" ref = {(el) => fiveAmSectionsRef.current[1] = el}>
                        <Sugar triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "3" ref = {(el) => fiveAmSectionsRef.current[2] = el}>
                        <Am triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "4" ref = {(el) => fiveAmSectionsRef.current[3] = el}>
                        <Pm triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "5" ref = {(el) => fiveAmSectionsRef.current[4] = el}>
                        <Beverages triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "6" ref = {(el) => fiveAmSectionsRef.current[5] = el}>
                        <Coffee triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "7" ref = {(el) => fiveAmSectionsRef.current[6] = el}>
                        <Tea triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CopyRightStyled>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                                <img src = "/Developed-By-LSD.svg"/>
                    </a>
                </CopyRightStyled>
            </FiveAmPageStyled>
        )
    }

    // if(triggerLanguage == "ar") {
    //     return (
    //         <FiveAmPageStyled>
    //             <SliderContainerStyled ref = {sliderContainer} onScroll={useHorizontalScrollEventEn} triggerLanguage="ar">
    //                 <NavTextStyledAr>
    //                     <a href = "#7" ref = {(el) => fiveAmCatRef.current[6] = el} onClick = {() => navClickHandler("7")}>
    //                         <span>الشاي</span>
    //                     </a>    
    //                 </NavTextStyledAr>
    //                 <NavTextStyledAr>
    //                     <a href = "#6" ref = {(el) => fiveAmCatRef.current[5] = el} onClick = {() => navClickHandler("6")}>
    //                         <span>القهوة</span>
    //                     </a>    
    //                 </NavTextStyledAr>
    //                 <NavTextStyledAr>
    //                     <a href = "#5" ref = {(el) => fiveAmCatRef.current[4] = el} onClick = {() => navClickHandler("5")}>
    //                         <span>المشروبات</span>
    //                     </a>    
    //                 </NavTextStyledAr>
    //                 <NavTextStyledAr>
    //                     <a href = "#4" ref = {(el) => fiveAmCatRef.current[3] = el} onClick = {() => navClickHandler("4")}>
    //                         <span>بي أم</span>
    //                     </a>    
    //                 </NavTextStyledAr>
    //                 <NavTextStyledAr>
    //                     <a href = "#3" ref = {(el) => fiveAmCatRef.current[2] = el} onClick = {() => navClickHandler("3")}>
    //                         <span>أي أم</span>
    //                     </a>    
    //                 </NavTextStyledAr>
    //                 <NavTextStyledAr>
    //                     <a href = "#2" ref = {(el) => fiveAmCatRef.current[1] = el} onClick = {() => navClickHandler("2")}>
    //                         <span>الحلو بدو حلو</span>
    //                     </a>    
    //                 </NavTextStyledAr>
    //                 <NavTextStyledAr>
    //                     <a href = "#1" ref = {(el) => fiveAmCatRef.current[0] = el} onClick = {() => navClickHandler("1")}>
    //                         <span>خبز و تغميس</span>
    //                     </a>    
    //                 </NavTextStyledAr>
    //             </SliderContainerStyled>
    //             <LanguageTriggerContainerStyled triggerLanguage = "ar">
    //                 <span onClick = {() => handleTriggerMenu("en")}>En</span>
    //             </LanguageTriggerContainerStyled>
    //             <CategoryContainerStyled>
    //                 <section id = "1" ref = {(el) => fiveAmSectionsRef.current[0] = el}>
    //                     <Breads triggerLanguage = {triggerLanguage}/>
    //                 </section>
    //             </CategoryContainerStyled>
    //             <CategoryContainerStyled>
    //                 <section id = "2" ref = {(el) => fiveAmSectionsRef.current[1] = el}>
    //                     <Sugar triggerLanguage = {triggerLanguage}/>
    //                 </section>
    //             </CategoryContainerStyled>
    //             <CategoryContainerStyled>
    //                 <section id = "3" ref = {(el) => fiveAmSectionsRef.current[2] = el}>
    //                     <Am triggerLanguage = {triggerLanguage}/>
    //                 </section>
    //             </CategoryContainerStyled>
    //             <CategoryContainerStyled>
    //                 <section id = "4" ref = {(el) => fiveAmSectionsRef.current[3] = el}>
    //                     <Pm triggerLanguage = {triggerLanguage}/>
    //                 </section>
    //             </CategoryContainerStyled>
    //             <CategoryContainerStyled>
    //                 <section id = "5" ref = {(el) => fiveAmSectionsRef.current[4] = el}>
    //                     <Beverages triggerLanguage = {triggerLanguage}/>
    //                 </section>
    //             </CategoryContainerStyled>
    //             <CategoryContainerStyled>
    //                 <section id = "6" ref = {(el) => fiveAmSectionsRef.current[5] = el}>
    //                     <Coffee triggerLanguage = {triggerLanguage}/>
    //                 </section>
    //             </CategoryContainerStyled>
    //             <CategoryContainerStyled>
    //                 <section id = "7" ref = {(el) => fiveAmSectionsRef.current[6] = el}>
    //                     <Tea triggerLanguage = {triggerLanguage}/>
    //                 </section>
    //             </CategoryContainerStyled>
    //             <CopyRightStyled>
    //                 <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
    //                             <img src = "/Developed-By-LSD.svg"/>
    //                 </a>
    //             </CopyRightStyled>
    //         </FiveAmPageStyled>
    //     )
    // }
}

const FiveAmPageStyled = styled.div`
    position: relative;
    background-color:white;
    width: 100vw;
`

const SliderContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    left: ${props => props.triggerLanguage == "en" ? "0%" : "20%"};
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    gap: 20px;
    align-items: center;
    width: 80%; //change when we add arabic
    height: 50px;
    z-index: 1;
    overflow: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
    display: none;
  }
`

const TestingBlockStyled = styled.div`
    position: relative;
   
    top: 50px;

    img {
        width: 100%;
    }

`

const NavTextStyled = styled.div`
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    font-family: "Avenir LT Std";
    font-size: 10px;
   

   span {
    z-index: 10;
    width: 100%;
   }
`

const NavTextStyledAr = styled.div`
    position: relative;
    font-family: "GE Dinar One-Light";
    white-space: nowrap;
    font-size: 11px;
`

const LanguageTriggerContainerStyled = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: center;
    z-index: 1;
    inset: 0px;
    left: ${props => props.triggerLanguage == "en" ? "80%" : "0%"};
    width: 20%;
    height: 50px;
    /* font-family: ${props => props.triggerLanguage == "en" ? "" : ""}; */ //do this later
    font-size: 12px;

     span {
        padding: 5px;
     }
`

const CategoryContainerStyled = styled.div`
    position: relative;
    margin-top: 80px;
`

export default FiveAmNew;