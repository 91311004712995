const breads = [
    {
        name_en: "Tandoor Bread",
        name_ar: "خبزة التنور",
        description_en: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/01 Tandoor Bread_WEB.jpg"
    },

    {
        name_en: "Sourdough",
        name_ar: "سوردو",
        description_en: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/02 Sourdough_WEB.jpg"
    },

    {
        name_en: "Sesame Chili Crackers",
        name_ar: "بسكويت الفلفل والسمسم",
        description_en: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/03 Sesame Chilli Crackers_WEB.jpg"
    },

    {
        name_en: "Bread Basket",
        name_ar: "سلة الخبز",
        description_en: "",
        price: "8",
        img_path: "/five-am-menu-designs/breads/04 Bread Basket_WEB.jpg"
    },

    {
        name_en: "Labneh & Makdous",
        name_ar: "لبنة ومكدوس ",
        description_en: "",
        price: "15",
        img_path: "/five-am-menu-designs/breads/05 Labneh & Makdous_WEB.jpg"
    },

    {
        name_en: "Zaatar Labneh & Pepper Marmalade",
        name_ar: "لبنة بالزعتر مع مربى الفلفل",
        description_en: "",
        price: "19",
        img_path: "/five-am-menu-designs/breads/06 Zaatar Labneh & Pepper Marmalade_WEB.jpg"
    },

    {
        name_en: "Whipped White Feta",
        name_ar: "جبنة الفيتا البيضاء المخفوقة",
        description_en: "",
        price: "12",
        img_path: "/five-am-menu-designs/breads/07 Whipped White Feta_WEB.jpg"
    },

    {
        name_en: "Clotted Ashta & Honey",
        name_ar: "قشطة وعسل",
        description_en: "",
        price: "28",
        img_path: "/five-am-menu-designs/breads/08 Clotted Ashta & Honey_WEB.jpg"
    },

    {
        name_en: "Hummus Palestine",
        name_ar: "حمص فلسطين",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/09 Hummus Palestine_WEB.jpg"
    },

    {
        name_en: "Hummus & Foul Modamas",
        name_ar: "حمص وفول مدمس",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/10 Hummus & Foul Mudammas_WEB.jpg"
    },

    {
        name_en: "Foul Modamas",
        name_ar: "فول مدمس",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/11 Foul_WEB.jpg"
    },

]

export default breads;