import { MenuPageStyled} from "../styles";
import { sekCategories, sekKhalifa } from "../data/foodMenuCategories";
import { BowlsGrid, SandwichesGrid, DrinksGrid, AppetizersGrid,  SaladsGrid } from "../components/MenuGrids/sekKhalifa";
import {useEffect, useState} from "react";
import $ from 'jquery';


import styled from "styled-components";

function SekKhalifaMenu() {

    // const [triggerCover, setTriggerCover] = useState(false);
    // const [showMenu, setShowMenu] = useState(true);

    // function triggerTest() {
    //     setTriggerCover(true);
    //     setTimeout(()=> {
    //         setShowMenu(true);
    //     }, 300)
    // }
    

    useEffect(() => {
        const sections = document.querySelectorAll("section");
        const navLi = document.querySelectorAll("nav .foodNavBar ul li");
       
        var navBar = $('.foodNavBar').offset().top;
        

        //uncomment navbar stuff if manager wants old styling
        const handleScroll = event => {
            console.log("trig")
            var currentScroll = window.pageYOffset;
            console.log("current scroll is: ", currentScroll);
            console.log("nav bar is : ", navBar);
            // if(currentScroll >= navBar)  {
            //     console.log("reached nav bar");
            //     $('.foodNavBar').css({
            //         position: 'fixed',
            //         top: '0',
            //         left: '0',
            //     })
            // } 
            
            //else {
            //     $('.foodNavBar').css({
            //         position: 'static',
            //     })
            // }

            
            var current = "";
            sections.forEach((section) => {
                const sectionTop = section.offsetTop; //how far the section is from top
                if (window.pageYOffset  >= sectionTop - 60) {
                    current = section.getAttribute("id");
                }
            });
    
            navLi.forEach((li) => {
                li.classList.remove("active");
                if(li.classList.contains(current)) {
                    li.classList.add("active");
                }
            });
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
        // window.onscroll = () => {
        //     var currentScroll = window.pageYOffset;
        //     console.log("current scroll is: ", currentScroll);
        //     console.log("nav bar is : ", navBar);
        //     if(currentScroll >= navBar)  {
        //         console.log("reached nav bar");
        //         $('.foodNavBar').css({
        //             position: 'fixed',
        //             top: '0',
        //             left: '0',
        //         })
        //     } else {
        //         $('.foodNavBar').css({
        //             position: 'static',
        //         })
        //     }
        //     var current = "";
        //     sections.forEach((section) => {
        //         const sectionTop = section.offsetTop; //how far the section is from top
        //         if (window.pageYOffset  >= sectionTop - 60) {
        //             current = section.getAttribute("id");
        //         }
        //     });
    
        //     navLi.forEach((li) => {
        //         li.classList.remove("active");
        //         if(li.classList.contains(current)) {
        //             li.classList.add("active");
        //         }
        //     });
        // };
    }, [])
    
    return (
        <>
         <MenuPageStyled>
                    <nav>
                        <div className = "foodNavBar">
                            <ul>
                                {
                                    sekKhalifa.map(({refNo, category, className}) => {
                                        return (
                                            <>
                                            <li class = {className}>
                                                <a href = {refNo} style = {{fontFamily: "Akizdenz-Grotesk BQ"}}>
                                                    {category}
                                                </a>
                                            </li>
                                        </>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </nav>
            <Test>
            <img src = "/sek-menu-designs/SEK-OnlineCover-TennisEvent-V1.jpg"/>
            </Test>
                    <nav>
                        <div className = "foodNavBar">
                            <ul>
                                {
                                    sekCategories.map(({refNo, category, className}) => {
                                        return (
                                            <>
                                            <li class = {className}>
                                                <a href = {refNo}>
                                                    {category}
                                                </a>
                                            </li>
                                        </>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </nav>
                    <section id = "category_1">
                        <SandwichesGrid/>
                    </section>
                    <section id = "category_2">
                        <BowlsGrid/>
                    </section>
                    <section id = "category_3">
                        <SaladsGrid/>
                    </section>
                    <section id = "category_4">
                        <AppetizersGrid/>
                    </section>
                    <section id = "category_5">
                        <DrinksGrid/>
                    </section>
                    <FollowUsStyled>
                        <a href = "https://linktr.ee/shawarmaelkhebbez" target = "_blank">
                            <img src = "/sek-menu-designs/closingPage.png"/>
                        </a>
                        <ArrowPointerStyled>
                        <a href = "https://linktr.ee/shawarmaelkhebbez" target = "_blank">
                            <img src = "/sek-menu-designs/closingPagePointer.png"/>
                        </a>
                        </ArrowPointerStyled>
                    </FollowUsStyled>
                    <CopyRightStyled>
                        <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                            <img src = "/Developed-By-LSD.svg"/>
                        </a>
                    </CopyRightStyled>
                </MenuPageStyled>
    </>
    )
    
}

const Test = styled.div`
    position: relative;
    img {
        margin-top: 15px;
        width:100%;
    }

   
`

const FollowUsStyled = styled.div`
    position: relative;
    background-color: #00547b;

    img {
        width:100%;
    }
`


const ArrowPointerStyled = styled.div`
    position: absolute;
    animation: bounce 1000ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    inset:0;
    top: 40px;
    left: 60px;

    @keyframes bounce {

    50% {
        transform: translate(-2px,-8px);
    }
        
    }

`

const CopyRightStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    width: 100%;
    img {
        width: 60%;
    }
`

export default SekKhalifaMenu;