import NavigationBar from "./NavigationBar";
import { useEffect, useState } from "react";
import { leBleuCategoryList } from "../data/LeBleu";

function EditDashboard({url, restaurantName, activeDashoard, triggerToolTip, setTriggerToolTip}) {

    const [grabbedItems, setGrabbedItems] = useState([]);
    const [item, setItem] = useState({});
    const [itemID, setItemID] = useState("");
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const [categoryList, setCategoryList] = useState([]); 

    useEffect(() => {
        switch(restaurantName) {
            case "le-bleu":
                setCategoryList(leBleuCategoryList)
            break;

        }
    }, [])

    return (
        <>
           {categoryList.length > 0 && <NavigationBar
            restaurantName = {restaurantName}
            setGrabbedItems = {setGrabbedItems}
            categoryList = {categoryList}
           />}
           {/* GRID HERE*/}
           {/* EDIT FORM  */}
        </>
    )
}

export default EditDashboard;