const pm = [
    {
        name_en: "Five Spiced Peach ",
        description_en: " With Cheese",
        name_ar: "سلطة الخمس بهارات مع الخوخ ",
        description_ar: "والجبنة",
        price: "29",
        img_path: "/five-am-menu-designs/pm/01 Five Spiced Peach_WEB.jpg"
    },

    {
        name_en: "Zucchini Salad ",
        description_en: " Zoodles, Cilantro, Sundried Tomato, Pecan Nuts",
        name_ar: "سلطة الكوسة ",
        description_ar: " معكرونة الكوسة، الكزبرة، الطماطم المجففة، الجوز البقان",
        price: "35",
        img_path: "/five-am-menu-designs/pm/02 Zucchini Salad_WEB.jpg"
    },

    {
        name_en: "Green Salad ",
        description_en: " Mix Leaves, Lemon Dressing",
        name_ar: "سلطة خضراء ",
        description_ar: " مزيج من ا􏰁وراق وصلصة الليمون",
        price: "35",
        img_path: "/five-am-menu-designs/pm/03 Green Salad_WEB.jpg"
    },

    {
        name_en: "Kale Quinoa Chicken Salad  ",
        description_en: " Kale, Quinoa, Grilled Chicken Breast",
        name_ar: "سلطة الكينوا والدجاج والكرنب ",
        description_ar: " كرنب، كينوا، صدور دجاج مشوية",
        price: "35",
        img_path: "/five-am-menu-designs/pm/04 Kale Chicken Quinoa Salad_WEB.jpg"
    },

    {
        name_en: "Grilled Tandoori Prawn Skewer  ",
        description_en: " Grilled Lime, Aleppo Pepper, Tandoori Cream",
        name_ar: "سيخ روبيان تندوري مشوي ",
        description_ar: " مع ليمون، فلفل حلبي، كريمة التندوري",
        price: "33",
        img_path: "/five-am-menu-designs/pm/05 Grilled Tandoori Prawn Skewer_WEB.jpg"
    },

    {
        name_en: "Pistachio Kafta Balls ",
        description_en: " With Sumac & Yogurt",
        name_ar: "كفتة الفستق الحلبي ",
        description_ar: " مع الروب والسماق",
        price: "25",
        img_path: "/five-am-menu-designs/pm/06 Pistachio Kafta Balls_WEB.jpg"
    },

    {
        name_en: "Chicken Tangdi Skewer & Peanut Sauce ",
        description_en: " Tahini, Pickled Onion, Chili Garlic Sauce",
        name_ar: " سيخ دجاج تانجدي مع صلصة الفول السوداني ",
        description_ar: " الطحينة، البصل المخلل، صلصة الثوم والفلفل الحار",
        price: "23",
        img_path: "/five-am-menu-designs/pm/07 Chicken Tangdi Skewer And Penut Sauce_WEB.jpg"
    },

    {
        name_en: "Penne Pink Pasta ",
        description_en: " Classic Pink Penne",
        name_ar: "بيني مع الصلصة الزهرية ",
        description_ar: " بيني الزهرية الكلاسيكية",
        price: "51",
        img_path: "/five-am-menu-designs/pm/08 Penne Pink Pasta_WEB.jpg"
    },

    {
        name_en: "Salmon ",
        description_en: " Grilled Salmon, Cilantro Lime Rice, Ponzu",
        name_ar: "سلمون ",
        description_ar: " سمك السلمون المشوي، أرز بالكزبرة والليمون، بونزو",
        price: "59",
        img_path: "/five-am-menu-designs/pm/09 Salmon_WEB.jpg"
    },


    {
        name_en: "Slow Cooked Short Ribs ",
        description_en: " with Cumin, Greek Yogurt, Chili Garlic Shatta",
        name_ar: "الأضلاع القصيرة المطهوة ببطء  ",
        description_ar: " مع الكمون، زبادي يوناني، شطة الثوم والفلفل الحار ",
        price: "58",
        img_path: "/five-am-menu-designs/pm/10 Slow Cooked Short Ribs_WEB.jpg"
    },

    {
        name_en: "Chicken Breast ",
        description_en: " Grilled Chicken, Cilantro Lime Rice, Cilantro Dressing",
        name_ar: "صدر دجاج ",
        description_ar: "دجاج مشوي، أرز بالكزبرة والليمون، صلصة الكزبرة ",
        price: "51",
        img_path: "/five-am-menu-designs/pm/11 Chicken Breast_WEB.jpg"
    },

    {
        name_en: "Rolled Steak Mushroom ",
        description_en: " Tenderloin, Mashed Potato, Mushroom Gravy",
        name_ar: "شرائح اللحم بالفطر ",
        description_ar: " شرائح لحم الخاصرة، بطاطس مهروسة، صلصة الفطر",
        price: "68",
        img_path: "/five-am-menu-designs/pm/12 Rolled steak mushroom_WEB.jpg"
    },

    {
        name_en: "Keema ",
        description_en: " Spiced Beef Minced, Cilantro, White Rice",
        name_ar: "كيما ",
        description_ar: "لحم بقري مفروم متبل بالكزبرة وا􏰁رز ا􏰁بيض ",
        price: "22",
        img_path: "/five-am-menu-designs/pm/13 Keema Beef_WEB.jpg"
    },
]


export default pm;