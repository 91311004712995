import styled from "styled-components";
import { pastriesAndCakes } from "../../../data/ergonCategories";

function PastriesAndCakesEn({}) {
    return (
        <div>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- koulouri seasame.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Koulouri seasame bagel
                    </p>
                    <PriceBoxStyled>11 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- nutella muffin.png" alt = "ergon nutella muffin"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Nutella muffin
                    </p>
                    <PriceBoxStyled>20 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- blueberry muffin.png" alt = "blueberry nutella muffin"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Blueberry muffin
                    </p>
                    <PriceBoxStyled>20 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- pistachio.png" alt = "pistachio  muffin"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Pistachio muffin
                    </p>
                    <PriceBoxStyled>20 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- banana.png" alt = "banana bread"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Banana bread
                    </p>
                    <PriceBoxStyled>22 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- carrot cake.png" alt = "carrot cake"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Carrot cake
                    </p>
                    <PriceBoxStyled>22 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- apple.png" alt = "apple pie"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Apple pie
                    </p>
                    <PriceBoxStyled>24 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- chocolate brownie.png" alt = "chocolate brownie with salted caramel"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Chocolate brownie with salted caramel
                    </p>
                    <PriceBoxStyled>20 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- triple choco cookies.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Sourdough croissant
                    </p>
                    <PriceBoxStyled>18 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- sourdough nutella.png" alt = "sourdough croissant filled with nutella"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Sourdough croissant filled with nutella
                    </p>
                    <PriceBoxStyled>28 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- sourdough pistachio.png" alt = "Sourdough croissant filled with pistachio"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Sourdough croissant filled with pistachio
                    </p>
                    <PriceBoxStyled>28 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- red velvet cookies.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Red velvet cookies
                    </p>
                    <PriceBoxStyled>16 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- tripple chocolate cookies.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Triple choco cookies
                    </p>
                    <PriceBoxStyled>16 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- walnut cookies.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Walnut cookies
                    </p>
                    <PriceBoxStyled>16 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/Marble-Pumkin-Cake_WEB.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Marble pumpkin cake with coffee
                    </p>
                    <PriceBoxStyled>22 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/Chocolate-cake-Frosting_WEB.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Chocolate cake frosting
                    </p>
                    <PriceBoxStyled>19 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
        </div>
    )
}

function PastriesAndCakesAr({}) {
    return (
        <div>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- koulouri seasame.png"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                بيغل كولوري بالسمسم
                </p>
                <PriceBoxStyledAr>اا ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
        <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- nutella muffin.png" alt = "ergon nutella muffin"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                مافن نوتيلا
                </p>
                <PriceBoxStyledAr>٢٠ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
        <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- blueberry muffin.png" alt = "blueberry nutella muffin"/>
        </ImageContainerStyledAr>
        <ItemDescriptionArStyled>
                <p>
                مافن بلوبيري
                </p>
                <PriceBoxStyledAr>٢٠ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- pistachio.png" alt = "pistachio  muffin"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                مافن الفستق
                </p>
                <PriceBoxStyledAr>٢٠ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- banana.png" alt = "banana bread"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                خبز الموز
                </p>
                <PriceBoxStyledAr>٢٢ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- carrot cake.png" alt = "carrot cake"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كيكة الجزر
                </p>
                <PriceBoxStyledAr>٢٢ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- apple.png" alt = "apple pie"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                فطيرة التفاح
                </p>
                <PriceBoxStyledAr>٢٤ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- chocolate brownie.png" alt = "chocolate brownie with salted caramel"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p style = {{textAlign: "right"}}>
                براونيز الشوكليت بالكراميل المالح
                </p>
                <PriceBoxStyledAr>٢٠ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- triple choco cookies.png"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كرواسان الساوردو
                </p>
                <PriceBoxStyledAr>١٨ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- sourdough nutella.png" alt = "sourdough croissant filled with nutella"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كرواسان الساوردو بالنوتيلا
                </p>
                <PriceBoxStyledAr>٢٨ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- sourdough pistachio.png" alt = "Sourdough croissant filled with pistachio"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كرواسان الساوردو بالفستق
                </p>
                <PriceBoxStyledAr>٢٨ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- red velvet cookies.png"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كوكيز الريد فيلفيت
                </p>
                <PriceBoxStyledAr>١٦ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- tripple chocolate cookies.png"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كوكيز الشوكلاتة
                </p>
                <PriceBoxStyledAr>١٦ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/pastries- walnut cookies.png"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كوكيز الجوز
                </p>
                <PriceBoxStyledAr>١٦ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/Marble-Pumkin-Cake_WEB.png"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كيكة اليقطين والقهوة
                </p>
                <PriceBoxStyledAr>١٢ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
        <SingleItemContainerStyledAr>
            <ImageContainerStyledAr>
                <img src = "/ergon-menu-desgins/Pastries & Cakes Pics/Marble-Pumkin-Cake_WEB.png"/>
            </ImageContainerStyledAr>
            <ItemDescriptionArStyled>
                <p>
                كيكة الشوكولاتة
                </p>
                <PriceBoxStyledAr>١٩ ر.ق</PriceBoxStyledAr>
            </ItemDescriptionArStyled>
        </SingleItemContainerStyledAr>
    </div>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`
    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`
const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

const PriceBoxStyledAr = styled(PriceBoxStyled)`
    text-align: right;
`

export {
    PastriesAndCakesEn,
    PastriesAndCakesAr,
}