

import { Grid } from "@mui/material"

function DrinksGrid() {
    return (
        <Grid container spacing = {0.1}>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Menu-Title-JPG/Menu-Title-06.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Drinks JPG/Drinks.jpg"/>
                </div>
            </Grid>
        </Grid>
    )
}

export default DrinksGrid;