import axios from "axios";

//shows the tooltip of buttons we click on
//function to handle tooltip - shows the pop up text when we hover over the button
//args: visible as a boolean, the tooltip to trigger and the hook to set it
function handleToolTip(visible, tooltip, setTriggerToolTip) {
    switch(tooltip) {
        case "delete":
            setTriggerToolTip(prevState => ({
                ...prevState,
                deleteTool: visible? "true" : false
            }))
        break;

        case "edit":
            setTriggerToolTip(prevState => ({
                ...prevState,
                editTool:visible? "true" : false
            }))
        break;

        case "hide":
            setTriggerToolTip(prevState => ({
                ...prevState,
                hideTool: visible? "true" : false
            }))
        break;
    }
}

//returns an object 

{/* ALL API GENERAL FUNCTIONS GO HERE */}
//handles hiding items from menus and updating the database
async function handleHideItem(url, item_id, show, grabbedItems) {
    const link = `${url}/${item_id}`
    const item_to_change = grabbedItems.find(item => item._id === item_id);
    item_to_change.old_image = item_to_change.image;
    item_to_change.hide = show;
    const itemData = new FormData();
    const imageFile = item_to_change.image;
    itemData.append('file', imageFile);

    for(const key in item_to_change) {
        if(item_to_change.hasOwnPropery(key) && key !== 'image') {
            if(Array.isArray(item_to_change[key])) {
                itemData.append(key, JSON.stringify(item_to_change[key]))
            } else {
                itemData.append(key, item_to_change[key]);
            }
        }
    }

    //call the api request for updating the item to hide the image
    await axios.patch(link, itemData, {
        headers: {
        },
        withCredentials: true
    })
    
}

//function to check for form fields errors before we submit the form
function checkForError(setFieldErrors, setTriggerFieldError, formData) {
  console.log(formData);
    var errorExists = 0;
    if(formData?.hasOwnProperty('name_en')) {
        if(formData.name_en == '') {
            console.log("i am triggered here");
            setFieldErrors((prevErrors) =>({
                ...prevErrors,
                "name_err": "Please provide a name for the item!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "name_err_bool": true
            }))
            errorExists = 1;
        }
    }
    if(formData?.hasOwnProperty('name_ar')) {
        if(formData.name_ar == '') {
            setFieldErrors((prevErrors) =>({
                ...prevErrors,
                "name_ar_err": "Please provide a name for the item!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "name_ar_err_bool": true
            }))
            errorExists = 1;
        }
    }
    if(formData?.hasOwnProperty('price')) {
        if(formData.price == '') {
            setFieldErrors((prevErrors) =>({
                ...prevErrors,
                "price_err": "Please provide a price for the item!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "price_err_bool": true
            }))
            errorExists = 1;
        }
    }
    if(formData?.hasOwnProperty('category')) {
        if(formData.category == '') {
            setFieldErrors((prevErrors) =>({
                ...prevErrors,
                "category_err": "Please provide a category for the item!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "category_err_bool": true
            }))
            errorExists = 1;
        }
    }
    if(formData?.hasOwnProperty('sub_category')) {
        if(formData.sub_category == '') {
            setFieldErrors((prevErrors) =>({
                ...prevErrors,
                "sub_category": "Please provide a name for the item!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "sub_category_err": true
            }))
            errorExists = 1;
        }
    }

    if(errorExists ==1) {
        return 1;
    } else {
        return false;
    }

}

//functions that handles the submit of the form to the database and updates the item (for edits) maybe we can use for
//add items too
async function handleSubmit(e, setFieldErrors, setTriggerFieldError, formData, activeDashboard) {
    e.preventDefault();
    console.log("current active dashboard is: ", activeDashboard)
    const errorExists = checkForError(setFieldErrors, setTriggerFieldError, formData)
    // if(errorExists) {
    //     //trigger a pop up that shows an error
    // } else {
    //     try {
    //         const itemData = new FormData();
    //         const imageFile = formData.image;
    //         itemData.append('file', imageFile);

    //         for (const key in formData) {
    //             if(formData.hasOwnProperty(key) && key !== 'image') {
    //                 if(Array.isArray(formData[key])) {
    //                     itemData.append(key, JSON.stringify(formData[key]))
    //                 } else {
    //                     itemData.append(key, formData[key]);
    //                 }
    //             }
    //         }
    //         //change url to dynamic!
    //         const {data:{msg}} = await axios.patch(`http://localhost:5000/api/v1/testMenus/${formData._id}`, itemData, {
    //             headers: {
    //             },
    //             withCredentials: true,
    //         })
    //         //setPopUpMessage(msg); work on this later!!
    //         //handleFormPopUpMsg(setShowAlert) work on this later!
    //     }
           
    //     catch (error) {
    //         //setPopUpMessage(error);
    //         //handleFormPopUpMsg(setShowAlert)
    //     }
    // }
}

export {
    handleToolTip,
    handleHideItem,
    checkForError,
    handleSubmit,

}