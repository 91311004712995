const sides = [
    {
        name_en: "Cumin Rice ",
        description_en: " ",
        name_ar: "أرز بالكمون ",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/sides/01 Cumin Rice_WEB.jpg"
    },

    {
        name_en: "Cilantro Lime Rice ",
        description_en: " ",
        name_ar: "أرز بالكزبرة والليمون ",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/sides/02 Cilantro Lime Rice_WEB.jpg"
    },

    {
        name_en: "French Fries ",
        description_en: " ",
        name_ar: "بطاطس مقلية ",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/sides/03 French Fries_WEB.jpg"
    },

    // {
    //     name_en: "Broccolini ",
    //     description_en: " ",
    //     name_ar: "بروكوليني ",
    //     description_ar: "",
    //     price: "13",
    //     img_path: "/five-am-menu-designs/sides/04 Broccolini_WEB.jpg"
    // },

    {
        name_en: "Asparagus ",
        description_en: " ",
        name_ar: "هليون ",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/sides/05 Asparagus_WEB.jpg"
    },

]

export default sides;